import './main';
import './plugins.min';
import './vendor/daterangepicker.min';

const App = {
	check: null,

    settings: {
	    _e: "click",
        loader: ".loader",
        loaderBack: ".loader .loader-back",
        loaderLogo: ".loader .loader-back .logo",
        bcgLazyLoad: ".bcg",
        btnEnviar: ".btn-enviar"
    },

    request: function request(data, callback) {
		$.ajax({
			url: 'ajax/data.php',
			type: 'POST',
			dataType: 'json',
			data: data,
			success: function(resp) {
				callback && callback(resp);
			}
		});
	},

    loader: function () {
        var loadedCount = 0;
        var imagesToLoad = $(this.settings.bcgLazyLoad).length;
        var loadingProgress = 0;

        $(this.settings.bcgLazyLoad)
            .imagesLoaded({
                background: true,
            })
            .progress(function (instance, image) {
                loadProgress();
            });

        function loadProgress(imgLoad, image) {
            loadedCount++;
            loadingProgress = loadedCount / imagesToLoad;

            TweenLite.to(progressTl, 0.4, {
                progress: loadingProgress,
                ease: Linear.easeNone,
            });
        }

        var progressTl = new TimelineMax({
            paused: true,
            onUpdate: progressUpdate,
            onComplete: loadComplete,
        });

        progressTl.to($(".progress span"), 1, {
            width: 100,
            ease: Linear.easeNone,
        });

        function progressUpdate() {
            loadingProgress = Math.round(progressTl.progress() * 100);
            //$(".loader .num").html(loadingProgress + "%");
        }

        function loadComplete() {
            window.setTimeout(function () {
                $(window).scrollTop(0);
            }, 0);

            var preloaderOutTl = new TimelineMax();
            preloaderOutTl
                .to(
                    $(App.settings.loaderLogo),
                    0.3,
                    { y: 100, autoAlpha: 0, ease: Back.easeIn },
                    0.2
                )
                .to($(App.settings.loaderBack), 0.4, {
                    xPercent: -100,
                    ease: Power4.easeInOut,
                })
                .set($(App.settings.loader), { className: "+=is-hidden" })
                .add(function () {
                    new WOW().init();
                    App.events();
                });

            return preloaderOutTl;
        }
    },

  clearString: function(string) {
    return string.toLowerCase().normalize('NFD')
                 .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1")
                 .normalize()
  },

  filterSearchOptions: function(filter) {
    filter = App.clearString(filter)
    $('.search_suggestions').find('.suggest-element').each(function() {
      if (App.clearString($(this).attr('data')).includes(filter)) {
        $(this).show()
      } else {
        $(this).hide()
      }
    })
  },

	initial: function(){



	    $(window).scroll(function() {
		    if ($(window).scrollTop() < 200) {
		        $('.bloque-search').removeClass('active');
			    setTimeout(function(){
				    $('.bloque-search').hide();
			    }, 300);
		    }
		    if ($(window).scrollTop() > 200) {
			    $('.bloque-search').show();
			    setTimeout(function(){
				    $('.bloque-search').addClass('active');
			    }, 300);
		    }
		});



		$('.js-hamburger').off("click").on("click", function() {
	        $('.js-hamburger').toggleClass('is-active');
        });


		$('input.i_fecha').daterangepicker({
			autoUpdateInput: false,
			autoApply: true,
			singleDatePicker: true,
			showDropdowns: true,
			minYear: 1950,
			minDate:new Date(),
			opens: "center",
			locale: {
            "format": 'YYYY-MM-DD',
		        "applyLabel": "Guardar",
	            "cancelLabel": "Cancelar",
	            "fromLabel": "Desde",
	            "toLabel": "Hasta",
	            "customRangeLabel": "Personalizar",
	            "daysOfWeek": [
	                "Do",
	                "Lu",
	                "Ma",
	                "Mi",
	                "Ju",
	                "Vi",
	                "Sa"
	            ],
	            "monthNames": [
	                "Enero",
	                "Febrero",
	                "Marzo",
	                "Abril",
	                "Mayo",
	                "Junio",
	                "Julio",
	                "Agosto",
	                "Setiembre",
	                "Octubre",
	                "Noviembre",
	                "Diciembre"
	            ],
	            "firstDay": 1
		    }
		});

		$('input.i_fecha').on('apply.daterangepicker', function (ev, picker) {
		    $(this).val(picker.startDate.format('YYYY-MM-DD'));


		    var dateobj = new Date();
			function pad(n) {return n < 10 ? "0"+n : n;}
			var result = pad(dateobj.getDate())+"/"+pad(dateobj.getMonth()+1)+"/"+dateobj.getFullYear();

		    var hoy = new Date();
		    //console.log(result, hoy.getHours()+3);
		    if(hoy.getMinutes()>=0 && hoy.getMinutes()<=15){
			    var min = "00"
		    }
		    if(hoy.getMinutes()>=15 && hoy.getMinutes()<=30){
			    var min = "15"
		    }
		    if(hoy.getMinutes()>=30 && hoy.getMinutes()<=45){
			    var min = "30"
		    }
		    if(hoy.getMinutes()>=45 && hoy.getMinutes()<=59){
			    var min = "45"
		    }

		    if(result==$('.i_fecha').val()){
				var hora = hoy.getHours()+3+':'+min;
				$('.i_time').val(hora);
				$('.i_time').on("change", function(){
					if($('.i_time').val()<hora && result==$('.i_fecha').val()){
						alert("No puedes seleccionar una hora menor.");
						$('.i_time').val(hora);
					}
				});
		    }else{
			    //var hora = hoy.getHours()+':'+min;
			    $('.i_time').val("21:00");
			    $('.i_time').on("change", function(){
					if($('.i_time').val()=="Ahora"){
						alert("No puedes seleccionar ahora.");
						$('.i_time').val("21:00");
					}
				});
		    }


		    $('.i_time').prop("disabled", "false");
			$('.i_time').removeAttr('disabled');

			//$('.lista-city').hide();

      var $whenInput = $('#search_when_input');
      var value = $(this).val();
      if (/\d{4}-\d{2}-\d{2}/.test(value)) {
        $whenInput.val('another-day');
      }
      else {
        $whenInput.val('now');
      }

		});

		/*let	sysDate  = new Date();
		let userDate = new Date(Date.UTC(sysDate.getFullYear(), sysDate.getMonth(), sysDate.getDate(),  sysDate.getHours(), sysDate.getMinutes(), 0));
		document.querySelector('.ctx-form').currentTime.valueAsDate = userDate;*/

		$('.i_city').focus(function() {
			$('.lista-city').show();
			setTimeout(function(){
				$('.lista-city').find("ul li.names").each(function(){
					var $this = $(this),
						_name = $this.data("name");

					$this.off("click").on("click", function(){
						$(".i_city").val(_name);
						$('.lista-city').hide();
					});
				});
			}, 300);
		});

		$('.i_fecha').focus(function() {
			$('.lista-city').hide();
		});

		$('.cubre-foto').on("click" ,function() {
			$('.lista-city').hide();
		});

    // se ocultan automaticamente las opciones del buscador por término
    $('.search_suggestions').hide();

    // se muestran las opciones del buscador al aplicar focus
    $('#search_term, .input-search').on('focus click', function() {
      if ($(this).attr('data-focus-again') == 'true') {
        $(this).attr('data-focus-again', false)
      } else {
        $(this).closest('div').find('.search_suggestions').show();
      }
    });

    // se olcultan las opciones del buscador al perder el focus
    $('#search_term, .input-search').on('focusout', function() {
      $(this).closest('div').find('.search_suggestions').hide();
      var input = $(this)
      if ($(this).attr('data-focus-again') == 'true') {
        setTimeout(function () { input.focus() }, 1)
      }
    })

    // filtrar opciones del buscador search_term
    $('#search_term, .input-search').on('input', function() {
      var filter = App.clearString($(this).val())
      App.filterSearchOptions(filter)
    })

    // aplicar el valor seleccionado al buscador search_term
    $('.suggest-element').on('mousedown', function() {
      var value = $(this).attr('data')
      $('#search_term, .input-search').val(value)
      $('#search_term, .input-search').attr('data-focus-again', true)
      App.filterSearchOptions(value)
    })
	},

    events: function () {
	    $('.wrapper').show();
	    this.initial();
    },
    init: function () {
        //this.loader();
        this.initial()
    },
};

export default App;
